@font-face {
  font-family: encodeSansSemiCondensedRegular;
  src: url(./styles/fonts/EncodeSansSC_SemiCondensed/EncodeSansSC_SemiCondensed-Regular.ttf);
}

@font-face {
  font-family: encodeSansSemiCondensedSemiBold;
  src: url(./styles/fonts/EncodeSansSC_SemiCondensed/EncodeSansSC_SemiCondensed-SemiBold.ttf);
}

@font-face {
  font-family: encodeSansSemiCondensedMedium;
  src: url(./styles/fonts/EncodeSansSC_SemiCondensed/EncodeSansSC_SemiCondensed-Medium.ttf);
}

/* 100 thin */
/* 200 xlight */
/* 300 light */
/* 400 regular */
/* 500 medium */
/* 600 sbold */
/* 700 bold */
/* 800 xbold */
/* 900 black */

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: encodeSansSemiCondensedMedium;
  /* font-family: encodeSansSemiCondensedRegular; */
  /* font-family: encodeSansSemiCondensedSemiBold; */
  background-color: #F2F2F2;
  color: #F2F2F2;
  height: 100px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #041834;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}

a:link, a:visited, a:hover, a:active {
  color: white;
  background-color: transparent;
  text-decoration: none;
}
